/* colors diagram */
.progress-bars-container {
  display: flex;
  align-items: flex-end;
  height: 3vw;
  width: 7vw;
}

.filler-outer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  margin-right: 1px;
}

.filler-outer:last-of-type {
  margin-right: 0;
}

.filler {
  transition: height 0.2s ease-in;
  width: 100%;
}

.filler-outer-red {
  background: rgba(255, 99, 61, 0.2);
}

.filler-outer-green {
  background: rgba(32, 185, 59, 0.2);
}

.filler-outer-blue {
  background: rgba(0, 122, 200, 0.2);
}

.filler-outer-purple {
  background: rgba(205, 100, 183, 0.2);
}

.filler-outer-brown {
  background: rgba(180, 137, 111, 0.2);
}

.filler-outer-yellow {
  background: rgba(255, 213, 0, 0.2);
}

.filler-outer-orange {
  background: rgba(255, 124, 46, 0.2);
}

.filler-outer-black {
  background: rgba(138, 140, 142, 0.2);
}

.filler-red {
  background: #ff633d;
}

.filler-green {
  background: #20b93b;
}

.filler-blue {
  background: #007ac8;
}

.filler-purple {
  background: #cd64b7;
}

.filler-brown {
  background: #b4896f;
}

.filler-yellow {
  background: #ffd500;
}

.filler-orange {
  background: #ff7c2e;
}

.filler-black {
  background: #8a8c8e;
}

/* center block */
.over-under-live {
  transition: opacity ease-in 0.5s;

  display: inline-block;
  opacity: 0.3;
  padding: 0.2vw 0.5vw;
}

.over-under-live.active {
  opacity: 1;
}

.preball-sum-title {
  opacity: 0.7;
  padding-bottom: 0.2vw;
  display: inline-block;
}

.prebals-live-sum {
  margin-left: 0.5vw;

  font-size: 1vw;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.8);
}
/* even odd progress bar */
.progress-bar-horizontal {
  position: relative;
  height: 8px;
  width: 95%;
  border-radius: 2px;
  background: #384252;
  border: 1px solid #333;
}

.progress-bar-horizontal .filler-horizontal {
  transition: width 0.3s ease-in;

  position: absolute;
  top: 0;
  left: 0;

  height: 6px;

  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
}
