.results-container {
    background-color: #05101a;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2%;
    margin-top: 2%;
}

.result-balls-container {
    width: 1500px;
    padding: 1%;
    height: 100%;
}

.results-column {
    position: relative;

    width: 16%;
    height: 17%;
    margin: 1% 1%;
}

.color-alias {
    position: relative;
}

.color-alias::after {
    content: "";

    transform: translateX(-50%);

    position: absolute;
    bottom: 10px;
    left: 50%;

    width: 0.8vw;
    height: 0.8vw;

    border-radius: 50%;
    display: none;
}
.column-pointer .color-alias::after{
    display: block;
}

.color-alias.alias-red::after {
    background-color: #ff5341;
}
.color-alias.alias-green::after {
    background-color: #38c731;
}
.color-alias.alias-blue::after {
    background-color: #3470b9;
}
.color-alias.alias-purple::after {
    background-color: #b83caa;
}
.color-alias.alias-brown::after {
    background-color: #4b2b1b;
}
.color-alias.alias-yellow::after {
    background-color: #ffe000;
}
.color-alias.alias-orange::after {
    background-color: #e26223;
}
.color-alias.alias-black::after {
    background-color: #181819;
}

.results-spaceholder {
    position: relative;
    z-index: 1;

    height: 100%;

    background-image: url(./../images/spaceholder.png);
    background-repeat: no-repeat;
    background-size: 100%;
}

.results-spaceholder .number-spaceholder {
    font-family: Lato;
    font-weight: 100;
    color: #f2fcfe;
    padding-top: 29%;
    font-size: 1.3vw;
    text-align: center;
}


.result-main-balls {
    width: 100%;
    align-items: center;
}

.results-line {
    background-image: url(./../images/line_vertical.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;

    width: 1%;
    height: 90%;
    margin: 0% 3%;
}

.result-cell p {
    margin-top: 0.5vw;
    margin-bottom: 1.4vw;
}


.results-line-small {
    background-image: url(./../images/line_vertical_small.png);
    background-repeat: no-repeat;
    width: 1%;
}

.last-row-in-result {
    display: flex;
    align-items: center;
}

.last-row-in-result .result-balls {
    width: 65px;
    height: 65px;
}