.game-timer {
  font-family: Lato;
  font-weight: 600;
  text-align: right;
  font-weight: 500;
  position: relative;
}

.timerLabel {
  font-family: Lato;
  font-weight: 600;
  font-size: 1.2vw;
  color: #f1f1f1;
}

.timerCountdown {
  margin-left: 15px;
  font-family: Lato;
  font-weight: 600;
  font-size: 3.2vw;
  color: #f2fcfe;
}
