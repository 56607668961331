
.pay-table-pattern {
    position: absolute;
    z-index: 1;
    background: url(./../images/pattern.png), url(./../images/pattern_border.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    height: 115%;
    width: 50%;
    margin: -5% 20%;
    animation: spin 30s linear infinite;
}

.pay-table {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3%;
    position: relative;
    z-index: 2;
}

.pay-table-label {
    font-size: 5.2vw;
    font-family: Lato;
    font-weight: bold;
    text-align: center;
    color: #f9fdfd;
    text-shadow: 0px 7px #14333e;
}

.pay-table-line {
    background: url(./../images/line_horizontal_big.png);
    background-repeat: no-repeat;
    background-size: 100%;
}

.pay-table-label-dark {
    font-size: 3.2vw;
    font-family: Lato;
    text-align: center;
    color: #14333e;
    text-shadow: 0px 0px;
}

.pay-table-label-dark-smaller {
    font-size: 2.2vw;
    font-family: Lato;
    text-align: center;
    color: #14333e;
    text-shadow: 0px 0px;
}

.bets-container {
    background-color: #05101a;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 1%;
    margin-top: 2%;
}
.bets-container .react-bs-table {
    padding-top: 30px;
}
.bets-container table {
    width: 100%;
}
.bets-container tbody tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.bets-container thead tr th {
    padding-bottom: 3px;
}
.bets-container tbody tr td {
    font-size: 1.45vw;
    font-weight: 300!important;
    padding-top: 6px;
}
.bets-container tbody tr td:last-child {
    color: #ffe000;
    font-weight: 500!important;
}

.countdown-label {
    font-size: 20vw;
    font-family: Lato;
    font-weight: bold;
    text-align: center;
    color: #f9fdfd;
    text-shadow: 0px 7px #14333e;
}

