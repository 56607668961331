.modal-backdrop {
    transition: opacity ease-out 300ms;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    opacity: 0;

    background-color: #d2d2d3;
}

.modal-backdrop.active {
     z-index: 9005;
     opacity: 0.6;
 }

.modal-wrapper {
    transition: all ease 300ms;

    position: absolute;
    top: -2000px;
    z-index: -1;

    opacity: 0;
    display: none;
}

.modal-wrapper.active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9006;

    display: block;
    width: 100%;
    height: 100%;
    opacity: 1;
}


.modal {
    transition: all ease 300ms;
    transform: translate(50%, -50%);

    position: absolute;
    top: -999px;
    right: 50%;
    z-index: 9006;

    opacity: 0;

    padding: 20px 40px;
    max-width: 500px;
    width: 90%;

    background-color: #0a2435;
}

.modal .modal-header {
    text-align: center;
}

.modal .modal-main {
    margin-bottom: 15px;
}

.modal.active {
    transition: all ease 300ms;
    top: 50%;
    opacity: 1;
}

.modal .modal-add-btn {
    position: absolute;
    top: 10px;
    left: 10px;

    cursor: pointer;
    color: white;
    font-weight: 300;
    font-size: 0.875rem;
}
.modal .modal-add-btn:hover {
    transition: all ease-out 300ms;
    filter: drop-shadow(0 0 0.25rem #e29f01);
}

.modal .modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;

    cursor: pointer;
    color: white;
    font-weight: 300;
    font-size: 0.875rem;
}
.modal .modal-close-btn:hover {
    transition: all ease-out 300ms;
    filter: drop-shadow(0 0 0.25rem #e29f01);
}

.jackpot-hit label {
    font-size: 0.9vw;
    color: #f7d503;

}
.jackpot-hit label strong {
    font-size: 2vw;
}


@media screen and (max-width: 520px) {
    .modal {
        padding: 15px 10px;
    }
}
#jackpotWinner{
    font-size: 1.4vw;
}

.uppercase{
    text-transform: uppercase;
}