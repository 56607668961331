.result-balls {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}
.result-balls > .roboto-condensed-regular {
  visibility: hidden;
}


.result-balls.results-spaceholder > .roboto-condensed-regular {
  visibility: visible;
  padding-top: 35%;

  font-size: 1vw;
  font-family: Roboto;
  color: #7e848c;
  text-align: center;
}

.one {
  background-image: url(./../images/numbers/small_balls/1.png);
}
.two {
  background-image: url(./../images/numbers/small_balls/2.png);
}
.three {
  background-image: url(./../images/numbers/small_balls/3.png);
}
.four {
  background-image: url(./../images/numbers/small_balls/4.png);
}
.five {
  background-image: url(./../images/numbers/small_balls/5.png);
}
.six {
  background-image: url(./../images/numbers/small_balls/6.png);
}
.seven {
  background-image: url(./../images/numbers/small_balls/7.png);
}
.eight {
  background-image: url(./../images/numbers/small_balls/8.png);
}
.nine {
  background-image: url(./../images/numbers/small_balls/9.png);
}
.ten {
  background-image: url(./../images/numbers/small_balls/10.png);
}
.eleven {
  background-image: url(./../images/numbers/small_balls/11.png);
}
.twelve {
  background-image: url(./../images/numbers/small_balls/12.png);
}
.thirteen {
  background-image: url(./../images/numbers/small_balls/13.png);
}
.fourteen {
  background-image: url(./../images/numbers/small_balls/14.png);
}
.fifteen {
  background-image: url(./../images/numbers/small_balls/15.png);
}
.sixteen {
  background-image: url(./../images/numbers/small_balls/16.png);
}
.seventeen {
  background-image: url(./../images/numbers/small_balls/17.png);
}
.eighteen {
  background-image: url(./../images/numbers/small_balls/18.png);
}
.nineteen {
  background-image: url(./../images/numbers/small_balls/19.png);
}
.twenty {
  background-image: url(./../images/numbers/small_balls/20.png);
}
.twenty-one {
  background-image: url(./../images/numbers/small_balls/21.png);
}
.twenty-two {
  background-image: url(./../images/numbers/small_balls/22.png);
}
.twenty-three {
  background-image: url(./../images/numbers/small_balls/23.png);
}
.twenty-four {
  background-image: url(./../images/numbers/small_balls/24.png);
}
.twenty-five {
  background-image: url(./../images/numbers/small_balls/25.png);
}
.twenty-six {
  background-image: url(./../images/numbers/small_balls/26.png);
}
.twenty-seven {
  background-image: url(./../images/numbers/small_balls/27.png);
}
.twenty-eight {
  background-image: url(./../images/numbers/small_balls/28.png);
}
.twenty-nine {
  background-image: url(./../images/numbers/small_balls/29.png);
}
.thirty {
  background-image: url(./../images/numbers/small_balls/30.png);
}
.thirty-one {
  background-image: url(./../images/numbers/small_balls/31.png);
}
.thirty-two {
  background-image: url(./../images/numbers/small_balls/32.png);
}
.thirty-three {
  background-image: url(./../images/numbers/small_balls/33.png);
}
.thirty-four {
  background-image: url(./../images/numbers/small_balls/34.png);
}
.thirty-five {
  background-image: url(./../images/numbers/small_balls/35.png);
}
.thirty-six {
  background-image: url(./../images/numbers/small_balls/36.png);
}
.thirty-seven {
  background-image: url(./../images/numbers/small_balls/37.png);
}
.thirty-eight {
  background-image: url(./../images/numbers/small_balls/38.png);
}
.thirty-nine {
  background-image: url(./../images/numbers/small_balls/39.png);
}
.fourty {
  background-image: url(./../images/numbers/small_balls/40.png);
}
.fourty-one {
  background-image: url(./../images/numbers/small_balls/41.png);
}
.fourty-two {
  background-image: url(./../images/numbers/small_balls/42.png);
}
.fourty-three {
  background-image: url(./../images/numbers/small_balls/43.png);
}
.fourty-four {
  background-image: url(./../images/numbers/small_balls/44.png);
}
.fourty-five {
  background-image: url(./../images/numbers/small_balls/45.png);
}
.fourty-six {
  background-image: url(./../images/numbers/small_balls/46.png);
}
.fourty-seven {
  background-image: url(./../images/numbers/small_balls/47.png);
}
.fourty-eight {
  background-image: url(./../images/numbers/small_balls/48.png);
}
