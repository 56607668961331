.ball-for-placeholder {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 83%;
  width: 4.5vw;
  height: 4.5vw;
}

.result-main-balls .ball-for-placeholder {
  background-size: 100%;
  width: 5vw;
  height: 5vw;
}

.ball_big {
  transform: skew(-4deg, 1deg);

  left: 49vw;
  top: 35.3vw;

  height: 2vw;
  width: 2vw;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.main-ball {
  transition: opacity ease-in 0.3s;

  left: 36vw;
  background-size: 100%;
  width: 3.7vw;
  height: 3.7vw;
  opacity: 0;

  transform: skewX(-3deg);
}

.main-ball[class*="ball-"] {
  opacity: 1;
}

.ball_first {
  top: 13.3vw;
  left: 35.9vw;
}

.ball_second {
  top: 17.3vw;
  left: 34.2vw;
}

.ball_third {
  top: 21.6vw;
  left: 33.5vw;
}

.ball_fourth {
  top: 25.8vw;
  left: 34vw;
}

.ball_fifth {
  top: 29.9vw;
  left: 35.7%;
}

.ball-one,.ball-1 {
  background-image: url(./../images/numbers/small_balls/1.png);
}
.ball-two, .ball-2 {
  background-image: url(./../images/numbers/small_balls/2.png);
}
.ball-three, .ball-3 {
  background-image: url(./../images/numbers/small_balls/3.png);
}
.ball-four, .ball-4 {
  background-image: url(./../images/numbers/small_balls/4.png);
}
.ball-five, .ball-5 {
  background-image: url(./../images/numbers/small_balls/5.png);
}
.ball-six, .ball-6 {
  background-image: url(./../images/numbers/small_balls/6.png);
}
.ball-seven, .ball-7 {
  background-image: url(./../images/numbers/small_balls/7.png);
}
.ball-eight, .ball-8 {
  background-image: url(./../images/numbers/small_balls/8.png);
}
.ball-nine, .ball-9 {
  background-image: url(./../images/numbers/small_balls/9.png);
}
.ball-ten, .ball-10 {
  background-image: url(./../images/numbers/small_balls/10.png);
}
.ball-eleven, .ball-11 {
  background-image: url(./../images/numbers/small_balls/11.png);
}
.ball-twelve, .ball-12 {
  background-image: url(./../images/numbers/small_balls/12.png);
}
.ball-thirteen, .ball-13 {
  background-image: url(./../images/numbers/small_balls/13.png);
}
.ball-fourteen, .ball-14 {
  background-image: url(./../images/numbers/small_balls/14.png);
}
.ball-fifteen, .ball-15 {
  background-image: url(./../images/numbers/small_balls/15.png);
}
.ball-sixteen, .ball-16 {
  background-image: url(./../images/numbers/small_balls/16.png);
}
.ball-seventeen, .ball-17 {
  background-image: url(./../images/numbers/small_balls/17.png);
}
.ball-eighteen, .ball-18 {
  background-image: url(./../images/numbers/small_balls/18.png);
}
.ball-nineteen, .ball-19 {
  background-image: url(./../images/numbers/small_balls/19.png);
}
.ball-twenty, .ball-20 {
  background-image: url(./../images/numbers/small_balls/20.png);
}
.ball-twenty-one, .ball-21 {
  background-image: url(./../images/numbers/small_balls/21.png);
}
.ball-twenty-two, .ball-22 {
  background-image: url(./../images/numbers/small_balls/22.png);
}
.ball-twenty-three, .ball-23 {
  background-image: url(./../images/numbers/small_balls/23.png);
}
.ball-twenty-four, .ball-24 {
  background-image: url(./../images/numbers/small_balls/24.png);
}
.ball-twenty-five, .ball-25 {
  background-image: url(./../images/numbers/small_balls/25.png);
}
.ball-twenty-six, .ball-26 {
  background-image: url(./../images/numbers/small_balls/26.png);
}
.ball-twenty-seven, .ball-27 {
  background-image: url(./../images/numbers/small_balls/27.png);
}
.ball-twenty-eight, .ball-28 {
  background-image: url(./../images/numbers/small_balls/28.png);
}
.ball-twenty-nine, .ball-29 {
  background-image: url(./../images/numbers/small_balls/29.png);
}
.ball-thirty, .ball-30 {
  background-image: url(./../images/numbers/small_balls/30.png);
}
.ball-thirty-one, .ball-31 {
  background-image: url(./../images/numbers/small_balls/31.png);
}
.ball-thirty-two, .ball-32 {
  background-image: url(./../images/numbers/small_balls/32.png);
}
.ball-thirty-three, .ball-33 {
  background-image: url(./../images/numbers/small_balls/33.png);
}
.ball-thirty-four, .ball-34 {
  background-image: url(./../images/numbers/small_balls/34.png);
}
.ball-thirty-five, .ball-35 {
  background-image: url(./../images/numbers/small_balls/35.png);
}
.ball-thirty-six, .ball-36 {
  background-image: url(./../images/numbers/small_balls/36.png);
}
.ball-thirty-seven, .ball-37 {
  background-image: url(./../images/numbers/small_balls/37.png);
}
.ball-thirty-eight, .ball-38 {
  background-image: url(./../images/numbers/small_balls/38.png);
}
.ball-thirty-nine, .ball-39 {
  background-image: url(./../images/numbers/small_balls/39.png);
}
.ball-forty, .ball-40 {
  background-image: url(./../images/numbers/small_balls/40.png);
}
.ball-forty-one, .ball-41 {
  background-image: url(./../images/numbers/small_balls/41.png);
}
.ball-forty-two, .ball-42 {
  background-image: url(./../images/numbers/small_balls/42.png);
}
.ball-forty-three, .ball-43 {
  background-image: url(./../images/numbers/small_balls/43.png);
}
.ball-forty-four, .ball-44 {
  background-image: url(./../images/numbers/small_balls/44.png);
}
.ball-forty-five, .ball-45 {
  background-image: url(./../images/numbers/small_balls/45.png);
}
.ball-forty-six, .ball-46 {
  background-image: url(./../images/numbers/small_balls/46.png);
}
.ball-forty-seven, .ball-47 {
  background-image: url(./../images/numbers/small_balls/47.png);
}
.ball-forty-eight, .ball-48 {
  background-image: url(./../images/numbers/small_balls/48.png);
}

.animated-one {
  background-image: url(./../animations/single_ball/1.gif);
}

.animated-two {
  background-image: url(./../animations/single_ball/2.gif);
}

.animated-three {
  background-image: url(./../animations/single_ball/3.gif);
}

.animated-four {
  background-image: url(./../animations/single_ball/4.gif);
}

.animated-five {
  background-image: url(./../animations/single_ball/5.gif);
}

.animated-six {
  background-image: url(./../animations/single_ball/6.gif);
}

.animated-seven {
  background-image: url(./../animations/single_ball/7.gif);
}

.animated-eight {
  background-image: url(./../animations/single_ball/8.gif);
}

.animated-nine {
  background-image: url(./../animations/single_ball/9.gif);
}

.animated-ten {
  background-image: url(./../animations/single_ball/10.gif);
}

.animated-eleven {
  background-image: url(./../animations/single_ball/11.gif);
}

.animated-twelve {
  background-image: url(./../animations/single_ball/12.gif);
}

.animated-thirteen {
  background-image: url(./../animations/single_ball/13.gif);
}

.animated-fourteen {
  background-image: url(./../animations/single_ball/14.gif);
}

.animated-fifteen {
  background-image: url(./../animations/single_ball/15.gif);
}

.animated-sixteen {
  background-image: url(./../animations/single_ball/16.gif);
}

.animated-seventeen {
  background-image: url(./../animations/single_ball/17.gif);
}

.animated-eighteen {
  background-image: url(./../animations/single_ball/18.gif);
}

.animated-nineteen {
  background-image: url(./../animations/single_ball/19.gif);
}

.animated-twenty {
  background-image: url(./../animations/single_ball/20.gif);
}

.animated-twenty-one {
  background-image: url(./../animations/single_ball/21.gif);
}

.animated-twenty-two {
  background-image: url(./../animations/single_ball/22.gif);
}

.animated-twenty-three {
  background-image: url(./../animations/single_ball/23.gif);
}

.animated-twenty-four {
  background-image: url(./../animations/single_ball/24.gif);
}

.animated-twenty-five {
  background-image: url(./../animations/single_ball/25.gif);
}

.animated-twenty-six {
  background-image: url(./../animations/single_ball/26.gif);
}

.animated-twenty-seven {
  background-image: url(./../animations/single_ball/27.gif);
}

.animated-twenty-eight {
  background-image: url(./../animations/single_ball/28.gif);
}

.animated-twenty-nine {
  background-image: url(./../animations/single_ball/29.gif);
}

.animated-thirty {
  background-image: url(./../animations/single_ball/30.gif);
}

.animated-thirty-one {
  background-image: url(./../animations/single_ball/31.gif);
}

.animated-thirty-two {
  background-image: url(./../animations/single_ball/32.gif);
}

.animated-thirty-three {
  background-image: url(./../animations/single_ball/33.gif);
}

.animated-thirty-four {
  background-image: url(./../animations/single_ball/34.gif);
}

.animated-thirty-five {
  background-image: url(./../animations/single_ball/35.gif);
}

.animated-thirty-six {
  background-image: url(./../animations/single_ball/36.gif);
}

.animated-thirty-seven {
  background-image: url(./../animations/single_ball/37.gif);
}

.animated-thirty-eight {
  background-image: url(./../animations/single_ball/38.gif);
}

.animated-thirty-nine {
  background-image: url(./../animations/single_ball/39.gif);
}

.animated-forty {
  background-image: url(./../animations/single_ball/40.gif);
}

.animated-forty-one {
  background-image: url(./../animations/single_ball/41.gif);
}

.animated-forty-two {
  background-image: url(./../animations/single_ball/42.gif);
}

.animated-forty-three {
  background-image: url(./../animations/single_ball/43.gif);
}

.animated-forty-four {
  background-image: url(./../animations/single_ball/44.gif);
}

.animated-forty-five {
  background-image: url(./../animations/single_ball/45.gif);
}

.animated-forty-six {
  background-image: url(./../animations/single_ball/46.gif);
}

.animated-forty-seven {
  background-image: url(./../animations/single_ball/47.gif);
}

.animated-forty-eight {
  background-image: url(./../animations/single_ball/48.gif);
}

@media screen and (max-height: 700px) {
  .ball-for-placeholder {
    width: 3.5vw;
    height: 3.5vw;
  }
}
