

[class^="lato-18"] {
  font-family: Lato;
  font-weight: lighter; 
  color: #f2fcfe;
}

.lato-18-semibold {
  font-size: 1vw;
  text-align: left;
}

.lato-18-semibold-centered {
  font-size: 0.8vw;
  text-align: center;
}

.lato-24-light {
  font-size: 1vw;
  font-family: Lato;
  font-weight: normal;
  text-align: center;
  color: #e5eef0;
  text-shadow: 0px 0px;
}
[class^="lato-36"] {
  font-size: 2.2vw;
  font-family: Lato;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 0px;
}
.lato-36-bold {
  color: #f9fcfd;
}

.lato-36-red {
  color: #ff5341;
}

.lato-36-green {
  color: #38c731;
}

.lato-36-blue {
  color: #3470b9;
}

.lato-36-purple {
  color: #b83caa;
}

.lato-36-brown {
  color: #4b2b1b;
}

.lato-36-yellow {
  color: #ffe000;
}

.lato-36-orange {
  color: #e26223;
}
.lato-36-black {
  color: #181819;
}

[class^="lato-48"] {
  font-size: 1.8vw;
  font-family: Lato;
  font-weight: bold;
  text-align: left;
  text-shadow: 0px 0px;
}

.lato-48-yellow {
  color: #ffe000;
}

.lato-48-orange {
  color: #ff7331;
}

.lato-48-red {
  color: #ff5341;
}
