.progress-line {
    display: flex;
    width: 100%;
    align-items: center;

}

.progress-line .title-progress-line {
    padding-right: 1vw;
    padding-left: 1vw;
    white-space: nowrap;
}

.progress-line .outer-progress-line {
    height: 1vw;
    width: 100%;

    border: 1px rgba(255, 255, 255, 0.7) solid;
}

.progress-line .filler {
    transition: width 1s linear;

    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
}