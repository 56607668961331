body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: var(--app-font);
  text-align: right;
  background: url(./assets/images/background.png);
  user-select: none;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  background-image: url(./assets/images/logo_lucky_six.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 37%;
  height: 100%;
  overflow: hidden;
  transition-delay: 10s;
  transition-property: transform;
  -webkit-animation: rotation 15s infinite linear;
  animation: rotation 15s infinite linear;
}
@-webkit-keyframes rotation {
  0%,
  50% {
    -webkit-transform: rotate(0deg);
  }
  67%,
  70% {
    -webkit-transform: rotate(1350deg);
  }
  70%,
  100% {
    -webkit-transform: rotate(1440deg);
  }
}
@keyframes rotation {
  0%,
  50% {
    -webkit-transform: rotate(0deg);
  }
  67%,
  70% {
    -webkit-transform: rotate(1350deg);
  }
  70%,
  100% {
    -webkit-transform: rotate(1440deg);
  }
}

.balls {
  width: 322px;
  margin-top: 18%;
  height: 918px;
  /* background: url(./assets/images/animation/balls_spin.png) no-repeat; */
  background-position: 0 0;
  transition: background-position 10s steps(103);
  transition-duration: 0s;
}
.balls:hover {
  transition-duration: 4.5s;
  background-position: -33200px 0;
}
/* animation: sprite-horizontal 4.5s steps(100) infinite; */
/* } */

@keyframes sprite-horizontal {
  from {
    background-position: 0 0%;
  }
  to {
    background-position: 100% 0;
  }
}

.stage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ryu {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  /* background: url(./assets/images/animation/balls_spin.png) no-repeat 0 0%; */
  background-size: 100%;
  animation: sprite 3.5s steps(10) infinite;
}

@keyframes sprite {
  from {
    background-position: 0 0%;
  }
  to {
    background-position: 0 100%;
  }
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: relative;
  top: 0;
  left: 0;
  width: 500%;
  height: 100%;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
