* {
  box-sizing: border-box;
  outline: none !important;
  line-height: 1.1em;
}

::-webkit-scrollbar {
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 14px;
}

.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 1);
  z-index: 999;
}

.preloader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5%;
}

.clearfix:after {
  display: table;
  content: " ";
  clear: both;
}

.show {
  display: block;
}

.mb-10px {
  margin-bottom: 8px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.centered {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.fullwidth {
  width: 100%;
}

.responsive {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.linked {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default !important;
}

.responsive-img-wrap > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.fullheight {
  height: 100%;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.ctx-primary {
  color: #aa6e07;
  background-color: #f6a821;
  border-color: #aa8a07;
}

.ctx-secondary {
  color: #191a1d;
  background-color: #484a54;
  border-color: #19191d;
}

.ctx-danger {
  color: #bb1417;
  background-color: #ec494c;
  border-color: #bb2c14;
}

.ctx-warning {
  color: #696900;
  background-color: #cfcf00;
  border-color: #586900;
}

.ctx-info {
  color: #1c39c5;
  background-color: #5f76e8;
  border-color: #1c1cc5;
}

.ctx-success {
  color: #075d00;
  background-color: #0ec300;
  border-color: #005d09;
}

.ctx-y {
  color: white;
  background-color: #f89803;
  border-color: #fcb84c;
}

.ctx-b {
  color: white;
  background-color: #1f80bf;
  border-color: #5ea7d7;
}

.ctx-r {
  color: white;
  background-color: #e74635;
  border-color: #f26f62;
}

.ctx-blck {
  color: white;
  background-color: #141414;
  border-color: #747474;
}

.ctx-head-dark {
  color: white;
  background-color: #2f4255;
  border-color: #0b0e13;
}

.ctx-head-light {
  color: white;
  background-color: #34495e;
  border-color: #10141c;
}

.ctx-bright {
  color: black;
  background-color: white;
  border-color: #cccccc;
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  src: url(./../fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  src: url(./../fonts/Lato-Black.ttf);
}

@font-face {
  font-family: Lato;
  font-weight: 600;
  font-style: normal;
  src: url(./../fonts/Lato-Black.ttf);
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  src: url(./../fonts/Lato-Heavy.ttf);
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  src: url(./../fonts/Lato-Heavy.ttf);
}

#gameBody h4 {
  margin-top: 0.6em;
  margin-bottom: 0.16667em;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-shadow: 0 2px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  text-align: center;
  margin: 1.33333em 0 0.16667em;
  font-family: Montserrat;
  font-weight: bold;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-shadow: 0 1px rgba(0, 0, 0, 0.4);
  }
}

h1 {
  font-size: 1.66667em;
}

h2 {
  font-size: 1.33333em;
}

h3 {
  font-size: 1.16667em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83333em;
}

h6 {
  font-size: 0.66667em;
}

html {
  background-color: black;
  color: white;
  font-family: Roboto, sans-serif;
}

html,
body {
  height: 100%;
}

.display-grid {
  display: grid;
}

.height-inherit {
  height: inherit;
  width: inherit;
}

.height-15 {
  height: 15%;
}
.height-60 {
  height: 60%;
}
.height-100 {
  height: 100%;
}

.justify-flex-end {
  justify-content: flex-end;
}

.margin-1 {
  margin: 1%;
}
.margin-2 {
  margin: 2%;
}
.margin-45 {
  margin: 45%;
}
.margin-bottom-1 {
  margin-bottom: 1%;
}
.margin-bottom-2 {
  margin-bottom: 2%;
}
.margin-top-3 {
  margin-top: -3%;
}
.margin-top-10 {
  margin-top: 10%;
}
.margin-top-25 {
  margin-top: 25%;
}
.margin-top-28 {
  margin-top: 28%;
}

.margin-top-30 {
  margin-top: 30%;
}
.margin-top-32 {
  margin-top: 32%;
}
.margin-17 {
  margin: -17%;
}
.margin-22 {
  margin: -22%;
}

.width-30 {
  width: 30%;
}

.width-50 {
  width: 50%;
}

.width-65 {
  width: 65%;
}

.width-75 {
  width: 75%;
}

.width-90 {
  width: 90%;
}

.width-100 {
  width: 100%;
}

.width-120 {
  width: 120%;
}

.padding-3 {
  padding: 3%;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.hidden {
  visibility: hidden;
}

.z-index-m1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}


/*button utility*/
   .btn {
     transition: all 200ms ease-in-out;

     display: inline-block;
     padding: 0.875rem 1.25rem 0.875rem;
     margin: 0.375rem 0;
     opacity: 0.95;
     position: relative;
     border: 0;
     border-radius: 4px;

     line-height: 1.25rem;

     font-weight: 600;
     font-size: 1rem;
     text-transform: uppercase;
     text-decoration: none;
     color: #fff;
     text-align: center;


}

.btn:hover {
  opacity: 1;
  cursor: pointer;
}

.btn.disable:hover {
  opacity: 0.85;
  cursor: not-allowed;
}

.btn-warning {
  background: #f7d503;
}
.btn.btn-warning:hover {
  background: #bfa815;
}

.btn.btn-info {
  background: #3576bb;
}
.btn.btn-info:hover {
  background: #3576bb;
}

