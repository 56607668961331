@-webkit-keyframes fadeOut {
  96% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  96% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes golden-blink {
  0% {
    text-shadow: -6px -2px 11px #b29c00, -8px 1px 11px #b29c00;
  }
  100% {
    text-shadow: 6px -3px 11px #b29c00, 8px 2px 11px #b29c00;
  }
}

@keyframes golden-blink {
  0% {
    text-shadow: -6px -2px 11px #b29c00, -8px 1px 11px #b29c00;
  }
  100% {
    text-shadow: 6px -3px 11px #b29c00, 8px 2px 11px #b29c00;
  }
}

@-webkit-keyframes blink {
  0% {
    text-shadow: -6px -2px 11px #919798, -8px 1px 11px #919798;
  }
  100% {
    text-shadow: 6px -3px 11px #919798, 8px 2px 11px #919798;
  }
}

@keyframes blink {
  0% {
    text-shadow: -6px -2px 11px #919798, -8px 1px 11px #919798;
  }
  100% {
    text-shadow: 6px -3px 11px #919798, 8px 2px 11px #919798;
  }
}

@keyframes moveBigBall {
  0% {
    left: 49vw;
    top: 35.3vw;

    height: 2vw;
    width: 2vw;
  }
  30% {
    left: 45vw;
    top: 30vw;

    height: 6vw;
    width: 6vw;
  }
  35% {
    left: 39vw;
    top: 11vw;

    height: 23vw;
    width: 23vw;
  }
  100% {
    left: 39vw;
    top: 11vw;

    height: 23vw;
    width: 23vw;
  }
}
