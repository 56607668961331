@import "grid.css";
@import "animation-keyframe.css";
@import "utils.css";
@import "font-utility.css";
@import "header.css";
@import "drawn-screen.css";
@import "draw-statistic.css";
@import "progress-line.css";
@import "result-table.css";
@import "bet-screens.css";
@import "modal-windows.css";
@import "numbers.css";
@import "balls.css";
@import "timer.css";

.constraint-16-9 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.footer {
  background: url(./../images/footer_line2.png);
  background-size: 100%;
  max-width: 100%;
  padding: 1% 1% 1.2% 0;
  z-index: 0;
}

.next-draw-label {
  font-size: 1.3vw;
  font-family: Lato;
  text-align: center;
  color: #f9fdfd;
  text-shadow: 0px 1px #14333e;
  padding: 5px 0 3px;
}

.next-draw-line {
  background-image: url(./../images/line_horizontal.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 1%;
}

.current-draw-line {
  background-image: url(./../images/line_horizontal.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 1%;
}

.star-small {
  background-image: url(./../images/star_outside.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 13%;
  width: 34%;
}

.star-small.star-small-result {
  height: 30px;
  width: 30px;
  min-width: 30px;
}

.bets-line {
  background-image: url(./../images/line_vertical.png);
  background-repeat: no-repeat;
  width: 3px;
  padding-bottom: 40%;
}

.bets-label {
  font-size: 1.6vw;
  text-align: left;
  color: #f9fdfd;
  text-shadow: 0px 0px;
  margin: 3%;
  width: 30%;
}

.parent {
  position: relative;
  width: 70%;
  margin: -10% auto 0 auto;
}

.parent:before {
  content: "";
  display: block;
  padding-top: 61.37%;
}

.footer-name {
  background-image: url(./../images/mohio_sports.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  margin: 1%;
}

.m-fadeIn {
  visibility: visible;
  opacity: 1;

  transition: all 0.2s ease-in-out;
  transition-delay: 1s;
}

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transition-delay: 3.26s;
}
